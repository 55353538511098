import React, { useEffect, useRef, useState } from "react"
import "../styles/searchBar.css"
import { Container } from "../styles/searchBarElements"
import OutsideAlerter from "./OutsideAlerter"
import { Link } from "gatsby"
import { suggestions, noResults } from "./suggestions.js"

export default function SearchBox({ showSearch, toggleShowSearch }) {

    const [filteredPlants, setFilteredPlants] = useState(noResults);

    const inputBox = useRef(null);

    useEffect(() => {
        inputBox.current && inputBox.current.focus()
    }, [showSearch])

    const handleOnChange = (e) => {
        let userData = e.target.value
        let emptyArray = []

        if (userData) {
            emptyArray = suggestions.filter((data) => {
                return data.name.toLocaleLowerCase().startsWith(userData.toLocaleLowerCase())
            })

            if (!emptyArray.length) {
                setFilteredPlants([{ name: "Nessuna pianta trovata" }])
            } else {
                setFilteredPlants(emptyArray)
            }
        } else {
            setFilteredPlants(noResults)
        }
    }

    return (
        <Container showSearch={showSearch}>
            <div className="wrapper active">
                <OutsideAlerter action={() => toggleShowSearch(false)}>
                    <div className="searchInput">
                        <input className="inputBox" type="text" placeholder="Cerca pianta..." ref={inputBox} onChange={handleOnChange} />
                        <ul className="autocompBox">
                            {filteredPlants.map(plant => {
                                return <Link to={"/piante/" + plant.slug}>{plant.name}</Link>
                            })}
                        </ul>
                        <img className="icon" src="/gray_search.png" />
                    </div>
                </OutsideAlerter>
            </div>
        </Container>
    )
}