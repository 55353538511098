import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import * as styles from "../styles/navigation.module.css"

const Chip = ({ title, src }) => {

  return (
    <div className={styles.chipContainer}>
      {src && <img src={src} className={styles.chipImage} alt={title} />}
      <p className={styles.chipText}>{title}</p>
    </div>
  )
}

export default function Navbar({ plant, setShowSearch }) {

  const data = useStaticQuery(graphql`
    query Site {
      site: site {
        siteMetadata {
          websiteName
        }
      }
    }
  `)

  const { websiteName } = data.site.siteMetadata

  return (
    <div className={styles.nav}>
      <div className={styles.navContainer}>
        <Link to='/'>
          <p className={styles.navLogoName}>{websiteName}</p>
        </Link>

        {/* Traforma in tabella */}
        {plant && plant.isAvailable &&
          <div className={styles.chipsContainer}>
            <Chip title={plant.navInfo.posizione} src={'/InfoIcons/house.png'} />
            <Chip title={plant.navInfo.luce} src='/InfoIcons/sun.png' />
            <Chip title={plant.navInfo.temperatura} src={'/InfoIcons/temperature.png'} />
            <Chip title={plant.navInfo.annaffiatura} src={'/InfoIcons/watering.png'} />
            <Chip title={plant.navInfo.rinvaso} src={'/InfoIcons/repot.png'} />
          </div>}

        <div className={styles.rightItemContainer} onClick={() => setShowSearch(true)}>
          <img className={styles.searchIcon} src='/search.png' />
        </div>
      </div>
    </div>
  )
}
