import React, { useState } from "react"
import Navbar from "./Navbar"
import * as styles from "../styles/home.module.css"
import SearchBox from "./SearchBox";
import { GlobalHotKeys } from "react-hotkeys";

export default function Layout({ plant, children }) {

    const [searchIsOpen, setSearchIsOpen] = useState(false);

    const preventDefaultHandlers = (handlers) => {
        const newHandlers = {};
        for (const [action, handler] of Object.entries(handlers)) {
          newHandlers[action] = (event) => {
            if (event) {
              event.preventDefault();
            }
            handler();
          };
        }
        return newHandlers;
      }
      
    const keyMap = {
        SHOW_SEARCH_MAC: "Cmd+f",
        SHOW_SEARCH_WINDOWS: "Ctrl+f"
    }

    const handlers = preventDefaultHandlers({
        SHOW_SEARCH_MAC: () => { setSearchIsOpen(true) },
        SHOW_SEARCH_WINDOWS: () => { setSearchIsOpen(true) }
    })

    return (
        <div>
            <GlobalHotKeys keyMap={keyMap} handlers={handlers}></GlobalHotKeys>
            <SearchBox showSearch={searchIsOpen} toggleShowSearch={setSearchIsOpen} />

            <Navbar plant={plant} setShowSearch={setSearchIsOpen} />

            <div>
                {children}
            </div>

            <footer className={styles.footerContainer}>
                <p className={styles.footerText}>© Copyright 2022 Il Giardino delle Driadi</p>
            </footer>
        </div>
    )
}