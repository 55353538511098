// query EveryPlant {
//     allPlantsDataJson(sort: { fields: name, order: ASC }) {
//       nodes {
//             slug
//             name
//         }
//     }
// }

// query PlaceholderPlants {
//   allPlantsDataJson(
//     sort: {fields: creationDate, order: DESC}
//     limit: 5
//     filter: {isAvailable: {eq: true}}
//   ) {
//     nodes {
//       slug
//       name
//     }
//   }
// }

export const noResults = [
  {
    "slug": "maranta",
    "name": "Maranta"
  },
  {
    "slug": "ficus_lyrata",
    "name": "Ficus Lyrata"
  },
  {
    "slug": "pothos",
    "name": "Pothos"
  },
  {
    "slug": "spathiphyllum",
    "name": "Spathiphyllum"
  },
  {
    "slug": "curcuma",
    "name": "Curcuma"
  }
]

export const suggestions = [
  {
    "slug": "ficus_lyrata",
    "name": "Ficus Lyrata"
  },
  {
    "slug": "pothos",
    "name": "Pothos"
  },
  {
    "slug": "spathiphyllum",
    "name": "Spathiphyllum"
  },
  {
    "slug": "curcuma",
    "name": "Curcuma"
  },
  {
    "slug": "ficus_elastica",
    "name": "Ficus Elastica"
  },
  {
    "slug": "syngonium",
    "name": "Syngonium"
  },
  {
    "slug": "ficus_benjamin",
    "name": "Ficus Benjamin"
  },
  {
    "slug": "adianthum",
    "name": "Adianthum"
  },
  {
    "slug": "aglaonema",
    "name": "Aglaonema"
  },
  {
    "slug": "alocasia",
    "name": "Alocasia"
  },
  {
    "slug": "anthurium",
    "name": "Anthurium"
  },
  {
    "slug": "beaucarnea",
    "name": "Beaucarnea"
  },
  {
    "slug": "calathea",
    "name": "Calathea"
  },
  {
    "slug": "calla",
    "name": "Calla"
  },
  {
    "slug": "cineraria",
    "name": "Cineraria"
  },
  {
    "slug": "chamaedorea",
    "name": "Chamaedorea"
  },
  {
    "slug": "croton",
    "name": "Croton"
  },
  {
    "slug": "dracena_marginata",
    "name": "Dracena Marginata"
  },
  {
    "slug": "felce",
    "name": "Felce"
  },
  {
    "slug": "falangio",
    "name": "Falangio"
  },
  {
    "slug": "guzmania",
    "name": "Guzmania"
  },
  {
    "slug": "giunco_spiralis",
    "name": "Giunco Spiralis"
  },
  {
    "slug": "hoya_carnosa_variegata",
    "name": "Hoya Carnosa Variegata"
  },
  {
    "slug": "kalanchoe_blossfeldiana",
    "name": "Kalanchoe Blossfeldiana"
  },
  {
    "slug": "kentia_doppia",
    "name": "Kentia Doppia"
  },
  {
    "slug": "maranta",
    "name": "Maranta"
  },
  {
    "slug": "monstera_deliciosa",
    "name": "Monstera Deliciosa"
  },
  {
    "slug": "orchidea_phalenopsis",
    "name": "Orchidea Phalenopsis"
  },
  {
    "slug": "pachira",
    "name": "Pachira"
  },
  {
    "slug": "pilea_peperomioides",
    "name": "Pilea Peperomioides"
  },
  {
    "slug": "philodendrum_kanadu",
    "name": "Philodendrum Kanadu"
  },
  {
    "slug": "sanseveria_laurenti",
    "name": "Sanseveria Laurenti"
  },
  {
    "slug": "sanseveria_cilindrica",
    "name": "Sanseveria Cilindrica"
  },
  {
    "slug": "stella_di_natale",
    "name": "Stella di Natale"
  },
  {
    "slug": "stella_di_natale_mouse",
    "name": "Stella di Natale Mouse"
  },
  {
    "slug": "stella_di_natale_winter_rose",
    "name": "Stella di Natale Winter Rose"
  },
  {
    "slug": "strelizia_reginae",
    "name": "Strelizia Reginae"
  },
  {
    "slug": "vrisea_era",
    "name": "Vrisea Era"
  },
  {
    "slug": "yucca",
    "name": "Yucca"
  },
  {
    "slug": "zamioculcas",
    "name": "Zamioculcas"
  },
  {
    "slug": "dracena_fragrans",
    "name": "Dracena Fragrans"
  }
]